import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const usePageTracking = () => {

  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {

    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize('UA-196392451-2', {debug: true, cookieDomain: 'auto', gaOptions: { cookieFlags: 'secure;samesite=none' }});
    }
    
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};




export default usePageTracking;

