import { Routes, Route, useLocation} from "react-router-dom";
import Prizes from './components/Prizes'
import Game from './components/Game'
import ReactGA from 'react-ga';
import usePageTracking from "./components/usePageTracking";
import './App.scss';



function App() {
  usePageTracking();
  const location = useLocation();


  return (
      <div className="app position-relative">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Game />} />
            <Route path="nagrade" element={<Prizes />} />
          </Routes>
      </div>
  );
}




export default App;