import {motion} from 'framer-motion/dist/es/index'
import ReactGA from 'react-ga';
import {Row, Col, Form} from 'react-bootstrap';




function FormStart(props) {

  const {
    validated,
    postAPI,
    email,
    omvCode,
    onChangeEmail,
    onChangeOmvCode,
    loading,
    errorMessage,
    isError

  } = props;

  const goToRegister = () => {
    if ('parentIFrame' in window) {
      /* eslint no-undef: "off"*/
      parentIFrame.sendMessage({action: 'close'}); 
      parentIFrame.sendMessage({action: 'redirect', content: 'https://www.omv.si/si-webportal/si/registration/how_to_register'})
    }


    ReactGA.event({
      category: 'CTA',
      action: 'registracija',
    });

  }


  return (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="box">
        <Form noValidate validated={validated} onSubmit={postAPI}>
          <Row className="mb-3">
            <Form.Group as={Col} className="col-12" >
              <Form.Label className="text-white mb-1">Email</Form.Label>
              <Form.Control
                required
                type="email"
                className="form-control"
                id="email"
                placeholder="Vpišite email"
                value={email}
                onChange={onChangeEmail} />
          
              <Form.Control.Feedback type="invalid">
                Vpišite email
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="12" >
              <Form.Label className="text-white mb-1">Številka kartice OMV SMILE & DRIVE</Form.Label>
              <Form.Control
                required
                type="text"
                className="form-control"
                id="omv_code"
                placeholder="Vpišite številko kartice"
                value={omvCode}
                onChange={onChangeOmvCode} 
                isInvalid={errorMessage && "true"}

              />
              <Form.Control.Feedback type="invalid">
                {errorMessage ? errorMessage : "Vpišite številko"} 
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <p className="fs-7 ms-4 text-white mb-1">Včlanite se! <a className="fw-bold link-register" onClick={goToRegister}>Brezplačna registracija</a>. </p>
          <Form.Group className="mb-3" controlId="formOptin">
            <Form.Check
              required
              label="Strinjam se s pravili igre ter obdelavo podatkov."
              label={(<><span className="text-white fs-8">Strinjam se s <a href={process.env.PUBLIC_URL +"/pravila/Pravila.pdf"} target="_blank" rel="noreferrer" className="text-white">pravili igre ter obdelavo podatkov.</a></span></>)}
              feedback="Strinjati se morate s pravili igre."
              feedbackType="invalid"
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition= {{ ease: "easeIn", duration: 0.15 }}
              type="submit"
              className="btn btn-gradient-yellow mt-3"
              
              disabled={loading}
            ><span>{loading ? 'Nalagam...' : 'PREIZKUSITE SVOJO SREČO'}</span></motion.button>
          </div>



          {isError && <small className="mt-3 d-inline-block text-danger">Napaka, poskusite še enkrat.</small>}

        </Form>
      </motion.div>
    )
}


export default FormStart;