import { useEffect, useRef } from "react";
import { motion } from "framer-motion/dist/es/index";
import SVG from "react-inlinesvg";
import { useMediaQuery } from "../utils";

import imageSendvic from "../assets/img/sendvic.svg";
import imageGorivo from "../assets/img/gorivo.svg";
import imageCistilo from "../assets/img/cistilo_stekla.svg";
import imagePranje from "../assets/img/pranje.svg";
import imageKava from "../assets/img/kava.svg";
import imageRogljicek from "../assets/img/rogljicek.svg";
import image500 from "../assets/img/500_tock.svg";
import imageBlank from "../assets/img/prazna.svg";
import imageStar from "../assets/img/zvezda.svg";

const testFunction = () => {
  var t = "skdjfjsdf";
};

function Buns(props) {
  const graph = useRef(null);
  const isSmall = useMediaQuery("(min-width: 620px)");

  const animationBuns = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.08,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const variants = isSmall
    ? {
        open: { opacity: 1, x: 0, transition: { delay: 0.4 } },
        active: { opacity: 1, x: "70%" },
      }
    : {
        open: { opacity: 1, x: 0 },
        active: { opacity: 1, y: "5%" },
      };

  const { isOpen, loading } = props;

  useEffect(() => {
    const spinner = graph.current;
    const circleElements = spinner.childNodes;

    let angle = 360 - 90;
    let dangle = 360 / circleElements.length;

    for (let i = 0; i < circleElements.length; i++) {
      let circle = circleElements[i];
      angle += dangle;
      circle.style.transform = `rotate(${angle}deg) translate(${
        spinner.clientWidth / 2
      }px) rotate(-${angle}deg)`;
    }
  }, []);

  return (
    <motion.div
      className="spinner"
      transition={{ type: "spring", bounce: 0.5 }}
      animate={isOpen ? "open" : "active"}
      variants={variants}
    >
      <div className={loading ? "spinning-star star" : "star"}>
        <SVG src={imageStar} />
      </div>
      <motion.div
        variants={animationBuns}
        initial="hidden"
        animate="show"
        className={loading ? "active buns" : "inactive buns"}
        ref={graph}
      >
        <motion.div variants={item} className="bun bun__1">
          <img src={imageSendvic} alt="bun" />
        </motion.div>
        <motion.div variants={item} className="bun bun__2">
          <img src={imageBlank} alt="bun" />
        </motion.div>
        <motion.div variants={item} className="bun bun__3">
          <img src={imageGorivo} alt="bun" />
        </motion.div>
        <motion.div variants={item} className="bun bun__4">
          <img src={imageCistilo} alt="bun" />
        </motion.div>
        <motion.div variants={item} className="bun bun__5">
          <img src={imageBlank} alt="bun" />
        </motion.div>
        <motion.div variants={item} className="bun bun__6">
          <img src={imagePranje} alt="bun" />
        </motion.div>
        <motion.div variants={item} className="bun bun__7">
          <img src={imageKava} alt="bun" />
        </motion.div>
        <motion.div variants={item} className="bun bun__8">
          <img src={imageRogljicek} alt="bun" />
        </motion.div>
        <motion.div variants={item} className="bun bun__9">
          <img src={imageBlank} alt="bun" />
        </motion.div>
        <motion.div variants={item} className="bun bun__10">
          <img src={image500} alt="bun" />
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default Buns;
