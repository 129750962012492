import { Link } from "react-router-dom";
import {motion} from 'framer-motion/dist/es/index'

import {Container, Row, Col} from 'react-bootstrap';

import imageSendvic from '../assets/img/sendvic.svg';
import imageGorivo from '../assets/img/gorivo.svg';
import imageCistilo from '../assets/img/cistilo_stekla.svg';
import imagePranje from '../assets/img/pranje.svg';
import imageKava from '../assets/img/kava.svg';
import imageRogljicek from '../assets/img/rogljicek.svg';
import image500 from '../assets/img/500_tock.svg';


const transitionPage = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transition: { duration: 0.5 }
  },
  out: {
    opacity: 0,
    transition: { duration: 0.5 }
  },
}




function Prizes() {



  return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={transitionPage}
      >
      <Container>
        <Row>
          <Col>
            <div className="intro">
            <h1 className="text-center text-white">Nagrade</h1>
            <p className="text-center text-white">Med 17. 12. 2021 in 2. 1. 2022 lahko člani Kluba OMV SMILE & DRIVE vsak dan trikrat preizkusite srečo in osvojite različne nagrade. Več  </p>
            </div>

            <Row className="ps-4 ps-sm-0">
              <Col className="mb-4" sm={4} >
                <div className="d-flex align-items-center prize-desc">
                  <img className="pe-3" src={imageGorivo} alt="gorivo" />
                  <div>
                    <p className="fs-6 text-white mb-0">14x</p>
                    <h2 className="text-white text-yellow-gradient">vrednostna kartica za gorivo za 30 €</h2>
                  </div>
                </div>
              </Col>
              <Col className="mb-4" sm={4} >
                <div className="d-flex align-items-center prize-desc">
                  <img className="pe-3" src={imageSendvic} alt="snedvič" />
                  <div>
                    <p className="fs-6 text-white mb-0">100x</p>
                    <h2 className="text-white text-yellow-gradient">kupon za sendvič VIVA</h2>
                  </div>
                </div>
              </Col>
              <Col className="mb-4" sm={4} >
                <div className="d-flex align-items-center prize-desc">
                  <img className="pe-3" src={imageCistilo} alt="čistilo za stekla" />
                  <div>
                    <p className="fs-6 text-white mb-0">100x</p>
                    <h2 className="text-white text-yellow-gradient">kupon za zimsko čistilo za stekla </h2>
                  </div>
                </div>
              </Col>
              <Col className="mb-4" sm={4} >
                <div className="d-flex align-items-center prize-desc">
                  <img className="pe-3" src={imagePranje} alt="Pranje" />
                  <div>
                    <p className="fs-6 text-white mb-0">100x</p>
                    <h2 className="text-white text-yellow-gradient">kupon za pranje TOP</h2>
                  </div>
                </div>
              </Col>
              <Col className="mb-4" sm={4} >
                <div className="d-flex align-items-center prize-desc">
                  <img className="pe-3" src={imageRogljicek} alt="rogljiček" />
                  <div>
                    <p className="fs-6 text-white mb-0">200x</p>
                    <h2 className="text-white text-yellow-gradient">kupon za rogljiček VIVA</h2>
                  </div>
                </div>
              </Col>
              <Col className="mb-4" sm={4} >
                <div className="d-flex align-items-center prize-desc">
                  <img className="pe-3" src={imageKava} alt="kava" />
                  <div>
                    <p className="fs-6 text-white mb-0">200x</p>
                    <h2 className="text-white text-yellow-gradient">kupon za kavo VIVA Café</h2>
                  </div>
                </div>
              </Col>
              <Col className="mb-4" sm={4} >
                <div className="d-flex align-items-center prize-desc">
                  <img className="pe-3" src={image500} alt="500" />
                  <div>
                    <p className="fs-6 text-white mb-0">400x</p>
                    <h2 className="text-white text-yellow-gradient">500 točk zvestobe</h2>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-center">
              <Link to="/"><motion.div

                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition= {{ ease: "easeIn", duration: 0.15 }}
                className="btn btn-gradient-yellow px-5"
              ><span>NAZAJ NA IGRO</span></motion.div></Link>
            </div>



          </Col>
        </Row>
      </Container>
      </motion.div>


    )
}


export default Prizes;