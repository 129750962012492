import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import Countdown from "react-countdown";
import Moment from "react-moment";

import Buns from "./Buns";
import FormStart from "./FormStart";

import { easeInOutCubic } from "../utils";

import useSound from "use-sound";
import { motion, AnimatePresence } from "framer-motion/dist/es/index";

import { Container, Row, Col } from "react-bootstrap";

// Import images

import imageStarON from "../assets/img/zvezdaON.svg";

import imageBadge from "../assets/img/omv_badge.png";
import imagePrizes from "../assets/img/icon-prizes.svg";
import imageClose from "../assets/img/close.svg";

// Import sounds
import soundPop from "../assets/sounds/pop.mp3";
import soundWin from "../assets/sounds/win.mp3";
import soundLose from "../assets/sounds/lose.wav";
import Snowfall from "react-snowfall";

const transitionPage = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
  out: {
    opacity: 0,
    transition: { duration: 0.5 },
  },
};

/*
const localEmail = JSON.parse(localStorage.getItem('email')) || {};
const localCode = JSON.parse(localStorage.getItem('omvCode')) || {};
*/

const tempOmvCode =
  "" + 705 + Math.floor(1000000000 + Math.random() * 9000000000);
const tempEmail = Math.floor(100000 + Math.random() * 900000) + "@escapebox.si";

function Game() {
  const [email, setEmail] = useState("");
  const [omvCode, setOmvCode] = useState("");
  const [data, setData] = useState([]);
  const [showForm, setShowForm] = useState(true);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [play] = useSound(soundPop, { volume: 0.15 });
  const [win] = useSound(soundWin, { volume: 0.1 });
  const [newChance] = useSound(soundLose, { volume: 0.1 });

  const setLocalStorage = () => {
    setEmail(JSON.parse(localStorage.getItem("email")));
    setOmvCode(JSON.parse(localStorage.getItem("omvCode")));
  };

  const postAPI = (e) => {
    // Data
    const data = {
      email: email,
      omv_code: omvCode,
    };

    setLoading(true);
    setIsError(false);
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setLoading(false);
    } else {
      axios
        .post("https://omvspin.escapebox.si/api/public/user", data)
        .then((res) => {
          if (localStorage.getItem("email") === null) {
            setEmail("");
            setOmvCode("");
          } else {
            setLocalStorage();
          }

          setShowForm(false);

          localStorage.setItem("email", JSON.stringify(email));
          localStorage.setItem("omvCode", JSON.stringify(omvCode));

          //?retry=1
          //retry=1&prize=none
          // &timeGap=10
          return axios.post(
            "https://omvspin.escapebox.si/api/public/game?timeGap=600",
            data,
            { headers: { Authorization: "Bearer " + res.data.token } }
          );
        })
        .then((res) => {
          setData(res.data);
          setIsOpen(false);
          setLocalStorage();
          setErrorMessage("");
        })

        .catch(function (error) {
          if (error.response) {
            setValidated(false);
            setErrorMessage(error.response.data.error_desc);
            console.log(error.response.data);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }

          setLoading(false);
        });
    }

    setValidated(true);
  };

  // Spinwheel logic
  const spinThatShit = async (prize) => {
    //Show random bun for no prize
    let items = [2, 5, 9];
    function random_item(items) {
      return items[Math.floor(Math.random() * items.length)];
    }

    let position;

    switch (prize) {
      case "sendvic":
        position = 1;
        break;
      case "gorivo":
        position = 3;
        break;
      case "cistilo_stekla":
        position = 4;
        break;
      case "pranje":
        position = 6;
        break;
      case "kava":
        position = 7;
        break;
      case "rogljicek":
        position = 8;
        break;
      case "500_tock":
        position = 10;
        break;
      case "none":
        position = random_item(items);
    }

    let bunsClass = ".buns";
    let itemClass = ".bun";
    let activeClass = "active";
    let star = document.querySelector(".star svg");
    let buns = document.querySelectorAll(itemClass);
    let bunsContainer = document.querySelector(bunsClass);

    const sleep = (time) => {
      return new Promise((resolve) => setTimeout(resolve, time));
    };

    let end = 50 + position;
    let index = 0;

    star.style.transform += "rotate(35deg)";
    var startTime = Date.now();
    for (let i = 0; i < end; i++) {
      let ease = easeInOutCubic(i, 50, 720, 100);

      await sleep(ease);

      if (buns.length <= 0) {
        index = 0;
        return;
      }

      star.style.transform = "rotate(" + i * 50 + "deg)";

      if (i === end - 1) {
        setLoading(false);
        setIsOpen(true);
        bunsContainer.classList.add(activeClass);
      }

      index %= buns.length; // Map index to valid values
      buns[(buns.length + index - 1) % buns.length].classList.remove(
        activeClass
      );
      buns[index].classList.add(activeClass);

      ++index; // Increase 'index'

      if (i < end - 1) {
        play();
      }
    }
  };

  useEffect(() => {
    setEmail(tempEmail);
    setOmvCode(tempOmvCode);
  });

  useEffect(() => {
    setEmail(tempEmail);
    setOmvCode(tempOmvCode);

    if (
      navigator.cookieEnabled &&
      typeof window.localStorage !== " undefined "
    ) {
      setEmail(JSON.parse(localStorage.getItem("email")));
      setOmvCode(JSON.parse(localStorage.getItem("omvCode")));
    }

    // Spinwheel
    if (
      data.try_2 === null &&
      data.try_3 === null &&
      data.currentTry !== undefined
    ) {
      spinThatShit(data.try_1);

      ReactGA.event({
        category: "CTA",
        action: "igraj",
      });
    } else if (data.try_3 === null && data.currentTry !== undefined) {
      spinThatShit(data.try_2);
      ReactGA.event({
        category: "CTA",
        action: "igraj-ponovno",
      });
    } else if (
      data.try_1 === "none" &&
      data.try_2 === "none" &&
      data.currentTry !== undefined
    ) {
      spinThatShit(data.try_3);
      ReactGA.event({
        category: "CTA",
        action: "igraj-ponovno",
      });
    } else {
      setLoading(false);
      setIsOpen(true);
    }
  }, [data]);

  const closeWindow = () => {
    if ("parentIFrame" in window) {
      /* eslint no-undef: "off"*/
      parentIFrame.sendMessage({ action: "close" });
    }

    ReactGA.event({
      category: "CTA",
      action: "zapri",
    });
  };

  const prizes = () => {
    ReactGA.event({
      category: "CTA",
      action: "nagrade",
    });
  };

  const more = () => {
    ReactGA.event({
      category: "CTA",
      action: "vec-info",
    });
  };

  const Content = () => {
    // Show content
    if (data.error) {
      return <TryAgainToday />;
    } else if (
      data.try_2 === null &&
      data.try_3 === null &&
      data.completed === false
    ) {
      return <AnotherChance />;
    } else if (data.try_3 === null && data.completed === false) {
      return <AnotherChance />;
    } else if (
      data.try_1 === "none" &&
      data.try_2 === "none" &&
      data.try_3 === "none"
    ) {
      return <Lost />;
    } else if (data.completed === true && data.currentTry == undefined) {
      return <AlreadyParticipated />;
    } else if (data.completed === true) {
      return <Win />;
    } else {
      return "";
    }
  };

  const Win = () => {
    win();

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.4 } }}
        exit={{ opacity: 0 }}
        className="box text-center">
        <h4 className="text-white mb-2">Čestitke!</h4>

        <div className="prize-status mb-2">
          <Row className="d-flex align-items-center">
            <Col md="3">
              <img
                src={process.env.PUBLIC_URL + "/img/" + data.prize + ".svg"}
                alt="Prize"
              />
            </Col>
            <Col md="9">
              <h5 className="text-sm-start text-center mb-0 text-blue">
                {data.prizeData.title}
              </h5>
            </Col>
          </Row>
        </div>

        <p className="text-white fs-6 mb-2">{data.prizeData.description}</p>

        <p className="text-white fs-6 mb-0">
          Jutri ste ponovno vabljeni, da zavrtite zvezdo in preizkusite svojo
          srečo.
        </p>

        <div className="d-grid gap-2">
          <motion.a
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ ease: "easeIn", duration: 0.15 }}
            className="btn btn-gradient-yellow mt-3"
            onClick={closeWindow}
            disabled={loading}>
            <span>KONČAJTE IGRO</span>
          </motion.a>
        </div>
      </motion.div>
    );
  };
  const AnotherChance = () => {
    newChance();
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.4 } }}
        exit={{ opacity: 0 }}
        className="box text-center">
        <div className="mb-3">
          <img
            src={imageStarON}
            className={data.try_3 === "none" ? "opacity-25" : undefined}
            alt="star"
          />{" "}
          <img
            src={imageStarON}
            className={data.try_2 === "none" ? "opacity-25" : undefined}
            alt="star"
          />{" "}
          <img
            src={imageStarON}
            className={data.try_1 === "none" ? "opacity-25" : undefined}
            alt="star"
          />
        </div>
        <h4 className="text-white">Škoda, tokrat vam ni uspelo.</h4>

        <hr />

        <p className="text-white lead">
          Čez 10 minut imate na voljo nov poskus. Danes imate na voljo
          <br />
          še{" "}
          <span className="text-yellow-gradient fw-black">
            {data.try_1 === "none" && data.try_2 === "none"
              ? "1 poskus"
              : "2 poskusa"}
          </span>
          .
        </p>

        <div className="d-grid gap-2">
          <motion.button
            onClick={postAPI}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ ease: "easeIn", duration: 0.15 }}
            type="submit"
            className="btn btn-gradient-yellow mt-3"
            disabled={loading}>
            <span>{loading ? "Nalagam..." : "PREIZKUSITE SVOJO SREČO"}</span>
          </motion.button>
        </div>
      </motion.div>
    );
  };

  const Lost = () => {
    win();
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.4 } }}
        exit={{ opacity: 0 }}
        className="box text-center">
        <h4 className="text-white mb-2">Škoda, danes ni vaš dan.</h4>

        <div className="prize-status mb-2">
          <Row className="d-flex align-items-center">
            <Col md="3">
              <img
                src={process.env.PUBLIC_URL + "/img/25_tock.svg"}
                alt="25 točk"
              />
            </Col>
            <Col md="9">
              <p className="text-sm-start text-center fs-6 mb-0 text-blue">
                Vseeno vam bomo na vašo kartico zvestobe OMV SMILE & DRIVE
                pripisali <strong>25 točk</strong>.
              </p>
            </Col>
          </Row>
        </div>

        <p className="text-white fs-6 mb-0">
          Jutri ste ponovno vabljeni, da zavrtite zvezdo in preizkusite svojo
          srečo.
        </p>

        {window.location.href.indexOf("?placement=inline") === -1 ? (
          <div className="d-grid gap-2">
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ ease: "easeIn", duration: 0.15 }}
              className="btn btn-gradient-yellow mt-3"
              onClick={closeWindow}>
              <span>KONČAJTE IGRO</span>
            </motion.a>
          </div>
        ) : undefined}
      </motion.div>
    );
  };

  const AlreadyParticipated = () => {
    win();

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.4 } }}
        exit={{ opacity: 0 }}
        className="box text-center">
        <h4 className="text-white mb-2">Danes ste že sodelovali.</h4>

        <div className="prize-status mb-2">
          <Row className="d-flex align-items-center">
            <Col md="12">
              <h5 className="text-sm-start text-center mb-0 text-blue">
                Jutri ste ponovno vabljeni, da zavrtite zvezdo in preizkusite
                svojo srečo.
              </h5>
            </Col>
          </Row>
        </div>

        <div className="d-grid gap-2">
          {window.location.href.indexOf("?placement=inline") === -1 ? (
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ ease: "easeIn", duration: 0.15 }}
              className="btn btn-gradient-yellow mt-3"
              onClick={closeWindow}
              disabled={loading}>
              <span>ZAPRITE OKNO</span>
            </motion.a>
          ) : undefined}
        </div>
      </motion.div>
    );
  };

  const TryAgainToday = () => {
    newChance();

    const renderer = ({ minutes, seconds, completed }) => {
      if (completed) {
        return (
          <motion.button
            onClick={postAPI}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ ease: "easeIn", duration: 0.15 }}
            type="submit"
            className="btn btn-gradient-yellow mt-3"
            disabled={loading}>
            <span>{loading ? "Nalagam..." : "PREIZKUSITE SVOJO SREČO"}</span>
          </motion.button>
        );
      } else {
        return (
          <h5 className="text-center mb-0 text-blue">
            {" "}
            <span>
              Še enkrat lahko sodelujete čez {minutes} minut {seconds} sekund
            </span>
          </h5>
        );
      }
    };

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.4 } }}
        exit={{ opacity: 0 }}
        className="box text-center">
        <div className="prize-status mb-2">
          <Row className="d-flex align-items-center">
            <Col md="12">
              <Countdown date={data.error.nextPlayAt} renderer={renderer} />
            </Col>
          </Row>
        </div>
      </motion.div>
    );
  };

  return (
    <>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={transitionPage}>
        <img className="icon-badge" src={imageBadge} alt="badge" />

        {window.location.href.indexOf("?placement=inline") === -1 ? (
          <img
            className="icon-close"
            onClick={closeWindow}
            src={imageClose}
            alt="close"
          />
        ) : undefined}

        <Link to="/nagrade" onClick={prizes}>
          <img
            className={
              loading ? "icon-prizes icon-prizes__disabled" : "icon-prizes"
            }
            src={imagePrizes}
            alt="nagrade"
          />
        </Link>
        <Container>
          <Row>
            <Col>
              <div className="intro">
                <h1 className="text-center text-white">
                  Ste rojeni pod srečno zvezdo?
                </h1>
                <p className="text-center text-white">
                  Do 2. 1. 2022 lahko člani Kluba OMV SMILE & DRIVE vsak dan
                  trikrat preizkusite srečo in osvojite eno od nagrad.{" "}
                  <a
                    onClick={more}
                    href="https://www.omv.si/sl-si/bencinski-servisi/akcije/srecna-zvezda"
                    target="_blank">
                    Več
                  </a>{" "}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="bun-container p-0">
              <Buns isOpen={isOpen} loading={loading} />
            </Col>
            <Col sm={6} className="pe-md-5">
              <AnimatePresence>
                {showForm ? (
                  <FormStart
                    validated={validated}
                    postAPI={postAPI}
                    email={email}
                    omvCode={omvCode}
                    onChangeEmail={(e) => setEmail(e.target.value)}
                    onChangeOmvCode={(e) => setOmvCode(e.target.value)}
                    loading={loading}
                    errorMessage={errorMessage}
                    isError={isError}
                  />
                ) : loading ? (
                  ""
                ) : (
                  <Content />
                )}
              </AnimatePresence>
            </Col>
          </Row>
        </Container>
      </motion.div>

      <Snowfall style={{ zIndex: "0" }} snowflakeCount={50} />

      {data && (
        <div className="d-none debug">
          <strong>Output:</strong>
          <br />
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </>
  );
}

export default Game;
